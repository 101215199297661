module.exports = [{
      plugin: require('/tmp/e55ed9a/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134673146-1"},
    },{
      plugin: require('/tmp/e55ed9a/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/e55ed9a/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/tmp/e55ed9a/node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://nckweb.com.ar"},
    },{
      plugin: require('/tmp/e55ed9a/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
